<template lang="pug">
.Wish
  a.Wish-btn(href='#' :class="{'Wish-btn-disabled': isLoading}", @click.prevent="wishlistCart", v-if="isEnabled")
    i.Wish-icon.fa(:class="(isFavorite ? 'fa-heart' : 'fa-heart not-favorite')")
</template>

<script>
export default {
  name: 'BlockwishlistBtn',
  props: {
    id_product: { type: Number, required: true },
    id_product_attribute: { type: Number, default: 0 }
  },
  data () {
    const wishlist = window.wishlistProductsIds || []

    return {
      isLoading: false,
      isEnabled: typeof window.WishlistCart === 'function',
      isFavorite: wishlist.includes(this.id_product),
      isLogged: window.isLogged,
      wishlist
    }
  },
  methods: {
    wishlistCart () {
      if (this.isLoading) {
        return false
      }

      const action = (!this.isFavorite ? 'add' : 'delete')

      if (this.isLogged) {
        this.isFavorite = true // show full icon
        this.isLoading = true // disable button
      }

      window.WishlistCart(
        'wishlist_block_list',
        action,
        this.id_product,
        this.id_product_attribute,
        1 // quantity
      )
    }
  },
  watch: {
    wishlist: function (list) {
      // Recheck when wishlist change
      this.isFavorite = list.includes(this.id_product)
      this.isLoading = false // enable button

      return this.isFavorite
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 20px;
$offset: calc(#{$width} + 6px);

.Wish {
  float: right;
  position: relative;
  right: $offset;

  @media (max-width: 767px) {
    right: 18px;
    top: 3px;
  }

  &-featured {
    position: relative;
    right: 26px;
    top: 3px;
  }

  &-btn {
    position: absolute;
    z-index: 1;
    font-size: $width;

    &-disabled {
      opacity: 0.5;
    }

    @media (max-width: 767px) {
      font-size: 15px;
    }
  }

  &-icon, &-icon::before {
    vertical-align: top;
  }
  .fa-heart {
    color: #af2127;
  }
  .not-favorite {
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #AF2127;
  }
  .not-favorite:hover {
    -webkit-text-fill-color: #af2127;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #af2127;
  }

}
</style>
